import { FILE_STATUS, IMPORT_JOB_STATUS } from '../constants';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return `${day}, ${time}`;
};

export const parseDonationAmount = (value, digits = 2) => {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: 'currency',
    currency: 'USD',
  }).format(value || 0);
};

export const parseAmount = (value, digits = 2) => {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value || 0);
};

export const parseSegmentFieldName = (fieldName) =>
  fieldName
    .replace(/_/g, ' ')
    .replace(/donor./, '')
    .replace(/last (.*?) at/i, 'most recent $1 (days ago)');

export const importJobStatusToFileStatus = (importJobStatus) => {
  switch (importJobStatus) {
    case IMPORT_JOB_STATUS.notStarted:
    case IMPORT_JOB_STATUS.validating:
    case IMPORT_JOB_STATUS.processing:
      return FILE_STATUS.pending;
    case IMPORT_JOB_STATUS.done:
      return FILE_STATUS.saved;
    case IMPORT_JOB_STATUS.failed:
      return FILE_STATUS.failed;
    default:
      return FILE_STATUS.failed;
  }
};
