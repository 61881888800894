/* eslint-disable react/prop-types */
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { IconMail, IconReportMoney } from '@tabler/icons-react';

import { TableRowActions } from 'ui';

import { REPORT_ACTIONS, reportTypesLabels } from '../../core/constants';
import NameCell from './NameCell';
import { getPercentage, parseDonationAmount } from '../../core/utils';
import CustomPagination from '../tables/CustomPagination';
import StatusLabel from './StatusLabel';

const getActionIcon = (action) => {
  switch (action) {
    case REPORT_ACTIONS.viewCampaign:
      return <IconMail size={20} />;
    case REPORT_ACTIONS.viewDonations:
      return <IconReportMoney size={20} />;
    default:
      return null;
  }
};

const ReportsTable = ({ excludedColumnKeys, data }) => {
  const navigate = useNavigate();
  const [hasScrollStarted, setHasScrollStarted] = useState(false);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollLeft > 0) {
      setHasScrollStarted(true);
    } else if (hasScrollStarted) {
      setHasScrollStarted(false);
    }
  };

  const handleSelectAction = useCallback(
    (action, report) => {
      switch (action) {
        case REPORT_ACTIONS.viewCampaign:
          navigate(`/emails/${report.campaignId}`);
          break;
        case REPORT_ACTIONS.viewDonations:
          navigate('/donations', { state: { campaignId: report.campaignId } });
          break;
        default:
          break;
      }
    },
    [navigate],
  );

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: () => 'Name',
          footer: (props) => props.column.id,
          cell: (info) => (
            <NameCell
              name={info.getValue()}
              sendAt={info.row.original.sendAt}
              updatedAt={info.row.original?.updatedAt}
              versions={info.row.original?.versions || 0}
            />
          ),
          minSize: 280,
        },
        {
          accessorKey: 'type',
          header: () => 'Type',
          footer: (props) => props.column.id,
          cell: (info) => reportTypesLabels[info.getValue()],
          minSize: 100,
        },
        {
          accessorKey: 'sendStatus',
          header: () => 'Status',
          footer: (props) => props.column.id,
          cell: (info) => <StatusLabel status={info.getValue()} />,
          minSize: 120,
        },
        {
          accessorKey: 'totalContacts',
          header: () => 'Audience Size',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
          minSize: 126,
        },
        {
          accessorKey: 'uniqueInteractions',
          header: () => 'Unique Interactions',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
          minSize: 174,
        },
        {
          accessorKey: 'interaction',
          header: () => 'Interactions',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
        },
        {
          accessorKey: 'donations',
          header: () => 'Donations',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
        },
        {
          accessorKey: 'amountRaised',
          header: () => 'Amount Raised',
          footer: (props) => props.column.id,
          cell: (info) => parseDonationAmount((info.getValue() || 0) / 100), // amountRaised is in cents
          minSize: 136,
        },
        {
          accessorKey: 'donationPageClicks',
          header: () => 'Donation Page Clicks',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
          minSize: 182,
        },
        {
          accessorKey: 'uniqueClicks',
          header: () => 'Unique Clicks',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
          minSize: 128,
        },
        {
          accessorKey: 'uniqueOpens',
          header: () => 'Unique Opens',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
          minSize: 124,
        },
        {
          accessorKey: 'click',
          header: () => 'clicks',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
        },
        {
          accessorKey: 'open',
          header: () => 'Opens',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue() || 0,
        },
        {
          accessorKey: 'delivered',
          header: () => 'Delivered',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), info.row.original?.totalContacts),
        },
        {
          accessorKey: 'deferred',
          header: () => 'Deferred',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), info.row.original?.totalContacts),
        },
        {
          accessorKey: 'dropped',
          header: () => 'Dropped',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), info.row.original?.totalContacts),
        },
        {
          accessorKey: 'bounce',
          header: () => 'Bounce',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), info.row.original?.totalContacts),
        },
        {
          accessorKey: 'spamreport',
          header: () => 'Spam Report',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), info.row.original?.totalContacts),
          minSize: 120,
        },
        {
          accessorKey: 'unsubscribe',
          header: () => 'Unsubscribe',
          footer: (props) => props.column.id,
          cell: (info) => getPercentage(info.getValue(), Number(info.row.original?.totalContacts)),
        },
        {
          accessorKey: 'createdAt',
          header: () => 'Date',
          footer: (props) => props.column.id,
          cell: (info) => new Date(info.getValue() * 1000).toLocaleDateString(),
          minSize: 110,
        },
        {
          accessorKey: 'actions',
          header: () => '',
          footer: (props) => props.column.id,
          cell: (info) => (
            <TableRowActions
              actions={[REPORT_ACTIONS.viewCampaign, REPORT_ACTIONS.viewDonations]}
              visibleActions={0}
              onSelectAction={(action) => handleSelectAction(action, info.row.original)}
              getActionIcon={getActionIcon}
            />
          ),
          size: 52,
        },
      ].filter((column) => {
        if (excludedColumnKeys) {
          return !excludedColumnKeys.includes(column.accessorKey);
        } else {
          return true;
        }
      }),
    [excludedColumnKeys, handleSelectAction],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto sm:rounded-lg" onScroll={handleScroll}>
        <table className="w-full text-left text-base text-gray-950 dark:text-gray-400">
          <thead className="bg-white-100 text-sm uppercase text-gray-400 dark:bg-gray-700 dark:text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-inherit">
                {headerGroup.headers.map((header, index) => {
                  const stickyCol = index === 0 && hasScrollStarted;
                  const stickyActionsCol = header.id === 'actions';
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`bg-inherit px-4 py-3 ${stickyCol ? 'sticky left-0 top-0' : ''} ${stickyActionsCol ? 'sticky right-0' : ''}`}
                      style={{
                        width: header.column.columnDef.size,
                        minWidth: header.column.columnDef.minSize,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                >
                  {row.getVisibleCells().map((cell, index) => {
                    const stickyCol = index === 0 && hasScrollStarted;
                    const stickyActionsCol = cell.column.id === 'actions';
                    return (
                      <td
                        key={cell.id}
                        className={`bg-inherit ${stickyCol ? 'sticky left-0 top-0' : ''} ${stickyActionsCol ? 'sticky right-0 p-2' : 'px-4 py-2'} `}
                        style={{
                          width: cell.column.columnDef.size,
                          minWidth: cell.column.columnDef.minSize,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[72px] border-b border-gray-50 bg-white-100 hover:bg-gray-10 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    <td className="px-4 py-2" colSpan={15}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
};

ReportsTable.propTypes = {
  data: PropTypes.array.isRequired,
  excludedColumnKeys: PropTypes.array,
};

export default ReportsTable;
