import { useSelector, shallowEqual } from 'react-redux';

import useActionCreator from '../../hooks/useActionCreator';
import {
  setCampaignTemplate,
  updateCampaignDetails,
  setSendTo,
  resetCampaignDetails,
  resetCampaignTemplate,
  setLoading,
  setError,
  setSuccess,
  getCampaigns,
  getCampaign,
  setViewTrashCan,
  softDeleteCampaign,
  cancelCampaign,
  restoreCampaign,
  restoreCampaigns,
  hardDeleteCampaigns,
  duplicateCampaign,
  setViewMode,
  setCalendarDate,
  setCalendarView,
  getSuggestedCampaigns,
  getLabels,
  createLabel,
} from './actions';

export const useCampaigns = () => {
  const campaignsState = useSelector((state) => state.campaigns, shallowEqual);

  return {
    ...campaignsState,
    setCampaignTemplate: useActionCreator(setCampaignTemplate),
    updateCampaignDetails: useActionCreator(updateCampaignDetails),
    setSendTo: useActionCreator(setSendTo),
    resetCampaignDetails: useActionCreator(resetCampaignDetails),
    resetCampaignTemplate: useActionCreator(resetCampaignTemplate),
    setLoading: useActionCreator(setLoading),
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    getCampaigns: useActionCreator(getCampaigns),
    getCampaign: useActionCreator(getCampaign),
    setViewTrashCan: useActionCreator(setViewTrashCan),
    softDeleteCampaign: useActionCreator(softDeleteCampaign),
    cancelCampaign: useActionCreator(cancelCampaign),
    restoreCampaign: useActionCreator(restoreCampaign),
    restoreCampaigns: useActionCreator(restoreCampaigns),
    hardDeleteCampaigns: useActionCreator(hardDeleteCampaigns),
    duplicateCampaign: useActionCreator(duplicateCampaign),
    setViewMode: useActionCreator(setViewMode),
    setCalendarDate: useActionCreator(setCalendarDate),
    setCalendarView: useActionCreator(setCalendarView),
    getSuggestedCampaigns: useActionCreator(getSuggestedCampaigns),
    getLabels: useActionCreator(getLabels),
    createLabel: useActionCreator(createLabel),
  };
};
