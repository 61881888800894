import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconArrowLeft,
  IconTrash,
  IconArrowBack,
  IconPlus,
  IconTable,
  IconCalendarEvent,
  IconCheck,
  IconX,
} from '@tabler/icons-react';

import Table from '../components/emails/Table';
import AlertMessage from '../components/alerts/AlertMessage';
import { useCampaigns } from '../store/campaigns/hooks';
import {
  campaignSendStatus,
  campaignSortBy,
  sortDirections,
  campaignSendStatusLabels,
  CAMPAIGNS_VIEW_MODES,
  campaignSortByLabels,
} from '../core/constants';
import { sortCampaigns } from '../core/utils';
import Loader from '../components/loader/Loader';
import useInterval from '../hooks/useInterval';
import Button from '../components/buttons/Button';
import Filters from '../components/emails/Filters';
import Chip from '../components/chip/Chip';
import MoreMenu from '../components/emails/MoreMenu';
import IconButton from '../components/buttons/IconButton';
import ConfirmDeleteModal from '../components/emails/ConfirmDeleteModal';
import Drawer from '../components/emails/Drawer';
import CalendarView from '../components/emails/CalendarView';
import SegmentControl from '../components/segment-control/SegmentControl';
import Sorters from '../components/tables/Sorters';

const POLLING_INTERVAL_IN_MS = 15000;

const VIEW_MODE_SEGMENTS = [
  { segment: CAMPAIGNS_VIEW_MODES.table, Icon: IconTable },
  { segment: CAMPAIGNS_VIEW_MODES.calendar, Icon: IconCalendarEvent },
];

const AUDIENCE_FILTER_SEGMENTS = [
  { segment: 'included', Icon: IconCheck, label: 'In Audience' },
  { segment: 'not_included', Icon: IconX, label: 'Not In Audience' },
];

const sortByOptions = [
  { label: campaignSortByLabels[campaignSortBy.sendStatus], value: campaignSortBy.sendStatus },
  { label: campaignSortByLabels[campaignSortBy.updatedAt], value: campaignSortBy.updatedAt },
  { label: campaignSortByLabels[campaignSortBy.name], value: campaignSortBy.name },
];

const audienceIncludesSegments = (campaign, segmentFilters) =>
  segmentFilters.some(
    (segment) =>
      (campaign.sendTo.all && !campaign.exclude?.segmentIds?.includes(segment.segmentId)) ||
      campaign.sendTo.segmentIds?.includes(segment.segmentId) ||
      campaign.sendTo.segment === segment.segmentId, // For backwards compatibility - campaign.segmentId has been deprecated
  );

const audienceIncludesTags = (campaign, tagFilters) =>
  tagFilters.some(
    (tag) =>
      (campaign.sendTo.all && !campaign.exclude?.tagIds?.includes(tag.id)) || campaign.sendTo.tagIds?.includes(tag.id),
  );

const Emails = () => {
  const [sortBy, setSortBy] = useState(campaignSortBy.updatedAt);
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [sendStatusFilters, setSendStatusFilters] = useState([]);
  const [labelFilters, setLabelFilters] = useState([]);
  const [segmentFilters, setSegmentFilters] = useState([]);
  const [tagFilters, setTagFilters] = useState([]);
  const [audienceFilterMatch, setAudienceFilterMatch] = useState('included');
  const [campaignErrors, setCampaignErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { selectedCampaignId } = useParams();
  const {
    error,
    success,
    setError,
    setSuccess,
    resetCampaignDetails,
    resetCampaignTemplate,
    updateCampaignDetails,
    getCampaigns: getCampaignsAction,
    viewTrashCan,
    campaigns,
    loading,
    setViewTrashCan,
    restoreCampaigns,
    hardDeleteCampaigns,
    viewMode,
    setViewMode,
    getLabels,
  } = useCampaigns();

  const getCampaigns = useCallback(async () => {
    try {
      const data = await getCampaignsAction().unwrap();
      if (viewTrashCan) return;
      setCampaignErrors(data.filter((item) => item.sendStatus === campaignSendStatus.error && !item.launched));
    } catch (err) {
      // Error handled by redux
    }
  }, [getCampaignsAction, viewTrashCan]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    getLabels();
  }, [getLabels]);

  const selectedCampaign = campaigns.find((campaign) => campaign.campaignId === selectedCampaignId);

  useEffect(() => {
    resetCampaignDetails();
    resetCampaignTemplate();
  }, [resetCampaignDetails, resetCampaignTemplate]);

  const isLaunching = useMemo(() => {
    return campaigns.some(
      ({ launched, sendStatus }) =>
        launched && [campaignSendStatus.draft, campaignSendStatus.error].includes(sendStatus),
    );
  }, [campaigns]);

  useInterval(getCampaigns, isLaunching ? POLLING_INTERVAL_IN_MS : null);

  const goToNewCampaign = () => {
    resetCampaignDetails();
    resetCampaignTemplate();
    navigate('/new-campaign');
  };

  const goToEditCampaign = (campaign) => {
    if (!campaign) return;
    resetCampaignTemplate();
    updateCampaignDetails(campaign);
    navigate(`/edit-campaign/${campaign.campaignId}`);
  };

  const goBackToCampaigns = () => {
    setViewTrashCan(false);
    getCampaigns();
  };

  const handleHardDeleteCampaigns = async () => {
    await hardDeleteCampaigns(selectedRows.map((row) => row.campaignId));
    tableRef.current?.resetSelectedRows();
    setDeleteOpen(false);
  };

  const handleRestoreCampaigns = async () => {
    await restoreCampaigns(selectedRows.map((row) => row.campaignId));
    tableRef.current?.resetSelectedRows();
  };

  const resetFilters = () => {
    setSendStatusFilters([]);
    setLabelFilters([]);
    setSegmentFilters([]);
    setTagFilters([]);
  };

  const data = useMemo(() => {
    let filteredCampaigns = campaigns;
    if (sendStatusFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) => sendStatusFilters.includes(campaign.sendStatus));
    }
    if (labelFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        labelFilters.some((label) => campaign.labels?.includes(label.labelId)),
      );
    }
    if (segmentFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        audienceFilterMatch === 'included'
          ? audienceIncludesSegments(campaign, segmentFilters)
          : !audienceIncludesSegments(campaign, segmentFilters),
      );
    }
    if (tagFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        audienceFilterMatch === 'included'
          ? audienceIncludesTags(campaign, tagFilters)
          : !audienceIncludesTags(campaign, tagFilters),
      );
    }
    if (sortBy) {
      filteredCampaigns = sortCampaigns([...filteredCampaigns], sortBy, sortDirection);
    }
    return filteredCampaigns;
  }, [
    campaigns,
    sendStatusFilters,
    sortBy,
    sortDirection,
    labelFilters,
    segmentFilters,
    tagFilters,
    audienceFilterMatch,
  ]);

  const campaignError = campaignErrors[0];

  const selectedRowsCount = selectedRows.length;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            {viewTrashCan && (
              <IconButton
                Icon={<IconArrowLeft size={20} />}
                color="transparent"
                className="-ml-2"
                onClick={goBackToCampaigns}
                srOnly="Go back to campaigns"
              />
            )}
            <h1 className="text-h3">
              {viewTrashCan
                ? selectedRowsCount
                  ? `${selectedRowsCount} selected`
                  : 'Campaigns Trash Can'
                : 'Campaigns'}
            </h1>
          </div>

          {viewTrashCan ? (
            <div className="flex items-center space-x-3">
              {selectedRowsCount > 0 && (
                <Button title="Restore selected" LeftIcon={IconArrowBack} onClick={handleRestoreCampaigns} />
              )}

              <Button
                title={selectedRowsCount ? 'Delete selected' : 'Delete all'}
                color="destructive"
                variant="outlined"
                LeftIcon={IconTrash}
                onClick={() => setDeleteOpen(true)}
                disabled={!campaigns.length}
              />
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              {viewMode === CAMPAIGNS_VIEW_MODES.table && (
                <>
                  <Sorters
                    options={sortByOptions}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                  />

                  <Filters
                    key={[
                      ...sendStatusFilters,
                      ...labelFilters.map(({ labelId }) => labelId),
                      ...segmentFilters.map(({ segmentId }) => segmentId),
                      ...tagFilters.map(({ id }) => id),
                    ].join(',')}
                    sendStatusFilters={sendStatusFilters}
                    setSendStatusFilters={setSendStatusFilters}
                    labelFilters={labelFilters}
                    setLabelFilters={setLabelFilters}
                    segmentFilters={segmentFilters}
                    setSegmentFilters={setSegmentFilters}
                    tagFilters={tagFilters}
                    setTagFilters={setTagFilters}
                  />
                </>
              )}

              <Button title="New Campaign" color="primary" LeftIcon={IconPlus} onClick={goToNewCampaign} />

              {viewMode === CAMPAIGNS_VIEW_MODES.table && <MoreMenu />}

              <SegmentControl
                segments={VIEW_MODE_SEGMENTS}
                selectedSegment={viewMode}
                onSelect={(segment) => setViewMode(segment)}
                segmentWidth={118}
                containerClassName="bg-gray-50"
              />
            </div>
          )}
        </div>
      </div>

      {(!!sendStatusFilters.length || !!labelFilters.length) && (
        <div className="flex flex-wrap items-center space-x-2">
          {sendStatusFilters.map((sendStatus) => (
            <Chip
              key={`chip-${sendStatus}`}
              label={campaignSendStatusLabels[sendStatus]}
              onDelete={() => setSendStatusFilters((prev) => prev.filter((s) => s !== sendStatus))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          {labelFilters.map((label) => (
            <Chip
              key={`chip-${label.labelId}`}
              label={label.name}
              onDelete={() => setLabelFilters((prev) => prev.filter((l) => l.labelId !== label.labelId))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={() => resetFilters()}>
            Reset all
          </button>
        </div>
      )}

      {(!!segmentFilters.length || !!tagFilters.length) && (
        <div className="flex flex-wrap items-center space-x-2">
          <SegmentControl
            segments={AUDIENCE_FILTER_SEGMENTS}
            selectedSegment={audienceFilterMatch}
            onSelect={setAudienceFilterMatch}
            segmentWidth={160}
            segmentHeight={32}
            buttonSize="sm"
            containerClassName="bg-gray-50"
          />

          {segmentFilters.map((segment) => (
            <Chip
              key={`chip-${segment.segmentId}`}
              label={segment.name}
              onDelete={() => setSegmentFilters((prev) => prev.filter((s) => s.segmentId !== segment.segmentId))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          {tagFilters.map((tag) => (
            <Chip
              key={`chip-${tag.id}`}
              label={tag.name}
              onDelete={() => setTagFilters((prev) => prev.filter((t) => t.id !== tag.id))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}
          <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={() => resetFilters()}>
            Reset all
          </button>
        </div>
      )}

      {viewMode === CAMPAIGNS_VIEW_MODES.table ? (
        <div className="relative flex-1 overflow-x-auto overflow-y-hidden">
          <Table key={`campaigns-${viewTrashCan}`} data={data} setSelectedRows={setSelectedRows} ref={tableRef} />

          {loading && <Loader />}
        </div>
      ) : (
        <div className="!mt-2">
          <CalendarView />
        </div>
      )}

      <ConfirmDeleteModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleHardDeleteCampaigns}
        title="Delete selected campaigns?"
        description="Are you sure you want to delete the selected campaigns? This action cannot be undone."
        loading={loading}
      />

      <Drawer selectedCampaign={selectedCampaign} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />

      <AlertMessage
        open={!!campaignError}
        message={
          <>
            <span>Launch error for campaign </span>
            <span className="cursor-pointer underline" onClick={() => goToEditCampaign(campaignError)}>
              {campaignError?.name}
            </span>
          </>
        }
        onClose={() => setCampaignErrors((prevState) => prevState.slice(1))}
        severity="error"
      />
    </div>
  );
};

export default Emails;
