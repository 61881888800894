import { useMemo, useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounceCallback, useIsMounted, useResizeObserver } from 'usehooks-ts';

import { Chip } from 'ui';

import { useCampaigns } from '../../store/campaigns/hooks';

const CampaignLabels = ({ labelIds = [] }) => {
  const { labels } = useCampaigns();
  const containerRef = useRef(null);
  const isMounted = useIsMounted();
  const [visibleCount, setVisibleCount] = useState(labels.length);
  const [{ width }, setSize] = useState({
    width: 228, // Best guess at initial width (260 - 32 padding)
  });
  const onResize = useDebounceCallback((size) => {
    if (!isMounted()) return;
    setSize(size);
  }, 200);

  useResizeObserver({
    ref: containerRef,
    onResize,
  });

  const campaignLabels = useMemo(() => {
    return [...labels].filter((label) => labelIds.includes(label.labelId)).sort((a, b) => a.name.localeCompare(b.name));
  }, [labels, labelIds]);

  useLayoutEffect(() => {
    if (!containerRef?.current) return;

    const container = containerRef.current;
    let totalWidth = 0;
    let count = 0;

    const chips = container.getElementsByClassName('chip-item');

    for (const chip of chips) {
      if (totalWidth + chip.offsetWidth + 4 > width) break;
      totalWidth += chip.offsetWidth + 4;
      count++;
    }

    setVisibleCount(count);
  }, [campaignLabels, isMounted, width]);

  const visibleLabels = campaignLabels.slice(0, visibleCount);
  const hiddenCount = campaignLabels.length - visibleCount;

  return (
    <div ref={containerRef} className="flex w-full items-center !space-x-1">
      {visibleLabels.map(({ labelId, name }) => (
        <Chip key={`chip-${labelId}`} label={name} className="chip-item text-sm font-normal" />
      ))}
      {hiddenCount > 0 && <span>+{hiddenCount}</span>}
    </div>
  );
};

CampaignLabels.propTypes = {
  labelIds: PropTypes.arrayOf(PropTypes.string),
};

export default CampaignLabels;
