import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { CAMPAIGN_SEND_STATUS } from 'common/constants';
import { ConfirmationModal } from 'ui';

import appSettings from '../../app-settings';
import { useCampaigns } from '../../store/campaigns/hooks';
import { restoreSuggestedCampaign } from '../../api/campaigns';
import ChangeCampaignName from './ChangeCampaignName';
import ContentFormCard from './FormCards/ContentFormCard';
import FormCard from './FormCards/FormCard';
import FromForm, { fromFormValidationSchema } from './Forms/FromForm';
import OverviewForm from './Forms/OverviewForm';
import SubjectForm, { subjectFormValidation } from './Forms/SubjectForm';
import TimeForm, { timeFormValidation } from './Forms/TimeForm';
import ToForm from './Forms/ToForm';

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;

const expandComponents = {
  to: 'to',
  from: 'from',
  subject: 'subject',
  time: 'time',
  template: 'template',
};

const CampaignForm = () => {
  const { campaignDetails, updateCampaignDetails, resetCampaignTemplate, setError, setSuccess } = useCampaigns();
  const { campaignId } = useParams();
  const [nameOpen, setNameOpen] = useState(!campaignId && !campaignDetails.campaignId);
  const [activeExpanded, setActiveExpanded] = useState();
  const [loading, setLoading] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const navigate = useNavigate();

  const setExpandedComponent = (component) => {
    setActiveExpanded((prevComponent) => (prevComponent === component ? undefined : component));
  };

  const handleCampaignNameClose = () => {
    if (!campaignId && !campaignDetails.campaignId) {
      navigate(-1);
    } else {
      setNameOpen(false);
    }
  };

  const handleCampaignNameConfirm = async (name) => {
    setError(false);
    setLoading(true);
    try {
      const { tempId, segmentId, ...data } = campaignDetails;
      if (!data.campaignId) {
        const update = { ...data, campaignId: tempId };
        if (name) update.name = name;
        await axios.post(CAMPAIGNS_URL, update);
      }
      updateCampaignDetails({ campaignId: tempId || data.campaignId, name });
      setNameOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const handleRestoreDraft = async () => {
    setRestoring(true);
    try {
      const originalCampaign = await restoreSuggestedCampaign(campaignDetails.campaignId);
      setConfirmOpen(false);
      resetCampaignTemplate();
      setSuccess('Suggested draft campaign restored successfully!');
      // This operation will cause this component to re-render, so leave it for last
      updateCampaignDetails(originalCampaign);
    } catch (err) {
      Sentry.captureException(err);
      setRestoring(false);
      setError(getNetworkError(err));
    }
  };

  const fromFormComplete = useMemo(() => {
    try {
      fromFormValidationSchema.validateSync(campaignDetails.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [campaignDetails.emailConfig]);

  const subjectFormComplete = useMemo(() => {
    try {
      subjectFormValidation.validateSync(campaignDetails.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [campaignDetails.emailConfig]);

  const completed = !!campaignDetails.launched || campaignDetails.sendStatus === CAMPAIGN_SEND_STATUS.sent;

  const sendTimeFormComplete = useMemo(() => {
    if (completed) return true;
    if (!campaignDetails.sendTime) return false;
    try {
      timeFormValidation.validateSync({ sendTime: campaignDetails.sendTime });
      return true;
    } catch (error) {
      return false;
    }
  }, [campaignDetails.sendTime, completed]);

  return (
    <>
      <div className="w-full max-w-3xl divide-y divide-gray-50 rounded-xl bg-white-100">
        <OverviewForm key={String(nameOpen)} onRestoreDraft={() => setConfirmOpen(true)} />

        <FormCard
          title={'To'}
          subtitle={'Who is receiving this campaign?'}
          form={<ToForm disabled={completed} />}
          isExpanded={completed || activeExpanded === expandComponents.to}
          handleClick={() => setExpandedComponent(expandComponents.to)}
          checked={
            campaignDetails.sendTo.tagIds?.length > 0 ||
            campaignDetails.sendTo.segmentIds?.length > 0 ||
            !!campaignDetails.segmentId ||
            campaignDetails.sendTo?.all
          }
        />

        <FormCard
          title={'From'}
          subtitle={'Who is sending this campaign?'}
          form={<FromForm disabled={completed} />}
          isExpanded={completed || activeExpanded === expandComponents.from}
          handleClick={() => setExpandedComponent(expandComponents.from)}
          checked={fromFormComplete}
        />

        <FormCard
          title={'Subject'}
          subtitle={'What is the subject line?'}
          form={<SubjectForm disabled={completed} />}
          isExpanded={completed || activeExpanded === expandComponents.subject}
          handleClick={() => setExpandedComponent(expandComponents.subject)}
          checked={subjectFormComplete}
        />

        <FormCard
          title={'Send Time'}
          subtitle={'When should the email be sent?'}
          form={<TimeForm disabled={completed} required />}
          isExpanded={completed || activeExpanded === expandComponents.time}
          handleClick={() => setExpandedComponent(expandComponents.time)}
          checked={sendTimeFormComplete}
        />

        <FormCard
          title={'Content'}
          subtitle={'What are you communicating?'}
          form={<ContentFormCard disabled={completed} />}
          isExpanded={completed || activeExpanded === expandComponents.template}
          handleClick={() => setExpandedComponent(expandComponents.template)}
          checked={!!campaignDetails.template.templateId}
        />
      </div>

      <ChangeCampaignName
        open={nameOpen}
        onClose={handleCampaignNameClose}
        onConfirm={handleCampaignNameConfirm}
        closeDisabled={!campaignDetails.campaignId}
        loading={loading}
      />

      <ConfirmationModal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleRestoreDraft}
        message="All changes to the draft will be lost and it will return to its original suggested state."
        loading={restoring}
      />
    </>
  );
};

export default CampaignForm;
