import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useIsMounted } from 'usehooks-ts';

import appSettings from '../../../app-settings';
import { useCampaigns } from '../../../store/campaigns/hooks';
import Loader from '../../loader/Loader';
import { useAccount } from '../../../store/account/hooks';
import Button from '../../buttons/Button';

const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const ContentFormCard = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { campaignDetails, campaignTemplate, setCampaignTemplate } = useCampaigns();
  const { account, loading: accountLoading } = useAccount();
  const isMounted = useIsMounted();

  const getTemplateCampaign = useCallback(
    async (templateId, version = 1) => {
      setError(false);
      setLoading(true);
      try {
        const templateRes = await axios.get(`${EMAIL_TEMPLATES_URL}/${templateId}/${version}`);
        const { downloadUrl, ...template } = templateRes.data;
        const htmlRes = await axios.get(downloadUrl, {
          headers: { Authorization: '' },
        });
        setCampaignTemplate({ ...template, html: htmlRes.data });
      } catch (err) {
        Sentry.captureException(err);
        if (isMounted()) setError(err?.message || true);
      }
      if (isMounted()) setLoading(false);
    },
    [isMounted, setCampaignTemplate],
  );

  useEffect(() => {
    if (campaignDetails.template.templateId && campaignDetails.template.templateId !== campaignTemplate.templateId) {
      getTemplateCampaign(campaignDetails.template.templateId, campaignDetails.template.version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToTemplateDesigner = () => {
    navigate(campaignTemplate.design ? '/email-editor' : '/email-templates');
  };

  const renderPreview = () => {
    if (loading || !campaignTemplate.html) return null;

    if (error) {
      return <p className="text-red-700">Oops, something went wrong!</p>;
    }

    return (
      <div className="h-[544px] w-full">
        <iframe className="size-full" srcDoc={campaignTemplate.html} />
      </div>
    );
  };

  const disabledButton =
    loading ||
    accountLoading ||
    (!account?.donationPageSubdomain && !account?.sandbox) ||
    (!!campaignDetails.template.templateId && campaignDetails.template.templateId !== campaignTemplate.templateId);

  return (
    <div className="relative space-y-6">
      {!disabled && <Button title="Design Email" onClick={goToTemplateDesigner} disabled={disabledButton} />}

      {!accountLoading && !account?.donationPageSubdomain && !account?.sandbox && (
        <p className="mt-4 text-center text-sm italic text-red-500">
          Please first deploy your donation page to be able to design your email
        </p>
      )}

      {renderPreview()}

      {(loading || accountLoading) && <Loader />}
    </div>
  );
};

ContentFormCard.propTypes = {
  disabled: PropTypes.bool,
};

ContentFormCard.defaultProps = {
  disabled: false,
};

export default ContentFormCard;
