import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { AutocompleteInput } from 'ui';

import { useCampaigns } from '../../store/campaigns/hooks';

const CampaignLabelsInput = ({ inputLabel = 'Campaign Labels', labelIds, onChange }) => {
  const { createLabel, labels } = useCampaigns();

  const options = useMemo(
    () =>
      [...labels]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((label) => ({ label: label.name, value: label.labelId })),
    [labels],
  );

  const selectedLabels = useMemo(
    () => labelIds?.map((labelId) => options.find((option) => option.value === labelId)) || [],
    [labelIds, options],
  );

  const handleLabelsChange = (labels) => {
    // Remap from options back to labels
    onChange(labels.map((label) => label.value));
  };

  const handleCreate = async (name) => {
    const label = await createLabel(name).unwrap();
    onChange([...labelIds, label.labelId]);
  };

  return (
    <AutocompleteInput
      label={inputLabel}
      placeholder="Type to search or create a label"
      multiple={true}
      options={options}
      onChange={handleLabelsChange}
      onCreate={handleCreate}
      value={selectedLabels}
    />
  );
};

CampaignLabelsInput.propTypes = {
  inputLabel: PropTypes.string,
  labelIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default CampaignLabelsInput;
