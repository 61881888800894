import axios from 'axios';

import appSettings from '../app-settings';

const API_URL = `${appSettings.baseUrl}`;

export const getAudienceSizeCount = async ({ tagIds, segmentIds, excludeTagIds, excludeSegmentIds }) => {
  const params = { unsubscribed: false };
  if (segmentIds?.length) params.segment_ids = segmentIds.join(',');
  if (tagIds?.length) params.list_ids = tagIds.join(',');
  if (excludeSegmentIds?.length) params.exclude_segment_ids = excludeSegmentIds.join(',');
  if (excludeTagIds?.length) params.exclude_list_ids = excludeTagIds.join(',');
  const res = await axios.get(`${API_URL}/contacts/count`, { params });
  return res.data.count;
};

export const getTemplate = async (templateId, templateVersion, suggested = false) => {
  let requestUrl = `${API_URL}/email-templates/${templateId}/${templateVersion}`;
  if (suggested) requestUrl += '?suggested=true';
  const templateResponse = await axios.get(requestUrl);
  const { downloadUrl, ...rest } = templateResponse.data;
  const htmlResponse = await axios.get(downloadUrl, {
    headers: { Authorization: '' },
  });
  return { ...rest, html: htmlResponse.data };
};

export const createTemplate = async (design, { order, type }, emailConfig) => {
  const response = await axios.post(`${API_URL}/email-templates`, {
    design,
    order,
    type,
    emailConfig,
  });
  return response.data;
};

export const updateTemplate = async (templateId, version, design, emailConfig) => {
  const res = await axios.put(`${API_URL}/email-templates/${templateId}/${version}`, {
    design: JSON.stringify(design),
    emailConfig,
  });
  return res.data;
};

export const uploadHtml = async (uploadUrl, html) => {
  await axios.put(uploadUrl, html, { headers: { 'Content-Type': 'text/html', Authorization: '' } });
};

export const getDefaultSectionTemplates = async (sections) => {
  if (!sections) return {};
  const sectionTemplates = await Promise.all(
    Object.keys(sections).map(async (sectionKey) => {
      const templateId = sections[sectionKey]?.templateId;
      if (!templateId) return { sectionKey, template: null };
      const template = await getTemplate(templateId, '1');
      return { sectionKey, template };
    }),
  );
  return sectionTemplates.reduce((acc, { sectionKey, template }) => {
    acc[sectionKey] = template;
    return acc;
  }, {});
};

export const acceptSuggestedCampaign = async (campaignId, templateId) => {
  const response = await axios.patch(`${API_URL}/campaigns/accept-suggested`, { campaignId, templateId });
  return response.data;
};

export const restoreSuggestedCampaign = async (campaignId) => {
  const response = await axios.patch(`${API_URL}/campaigns/restore-suggested/${campaignId}`);
  return response.data;
};

export const getSuggestedCampaigns = async () => {
  const response = await axios.get(`${API_URL}/campaigns/suggested`);
  return response.data;
};

export const sendTestEmail = async (data) => {
  await axios.post(`${API_URL}/campaigns/test-email`, data);
};

export const getLabels = async () => {
  const response = await axios.get(`${API_URL}/campaigns/labels`);
  return response.data;
};

export const createLabel = async (name) => {
  const response = await axios.post(`${API_URL}/campaigns/labels`, { name });
  return response.data;
};
