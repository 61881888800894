import { useState } from 'react';
import { Menu, MenuItem, Collapse } from '@mui/material';
import { IconFilter, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import Checkbox from '../inputs/Checkbox';
import { campaignSendStatus, campaignSendStatusLabels } from '../../core/constants';
import { useCampaigns } from '../../store/campaigns/hooks';
import { useTags } from '../../store/tags/hook';
import { useSegments } from '../../store/segments/hook';

const getButtonClassName = (open, active) => {
  if (open) return 'text-primary-600';
  if (active) return 'text-primary-700 !bg-primary-50 border-primary-200';
  return '';
};

const Filters = ({
  sendStatusFilters,
  setSendStatusFilters,
  labelFilters,
  setLabelFilters,
  segmentFilters,
  setSegmentFilters,
  tagFilters,
  setTagFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [stageOpen, setStageOpen] = useState(false);
  const [labelsOpen, setLabelsOpen] = useState(false);
  const [segmentsOpen, setSegmentsOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [selectedSendStatus, setSelectedSendStatus] = useState(sendStatusFilters);
  const [selectedLabels, setSelectedLabels] = useState(labelFilters);
  const [selectedSegments, setSelectedSegments] = useState(segmentFilters);
  const [selectedTags, setSelectedTags] = useState(tagFilters);
  const { labels } = useCampaigns();
  const { segments, loading: segmentsLoading } = useSegments();
  const { tags, loading: tagsLoading } = useTags();
  const loading = segmentsLoading || tagsLoading;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSendStatus = (sendStatus) => {
    if (selectedSendStatus.includes(sendStatus)) {
      setSelectedSendStatus((prev) => prev.filter((s) => s !== sendStatus));
      return;
    }
    setSelectedSendStatus((prev) => [...prev, sendStatus]);
  };

  const handleSelectLabel = (label) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels((prev) => prev.filter((l) => l.labelId !== label.labelId));
      return;
    }
    setSelectedLabels((prev) => [...prev, label]);
  };

  const handleSelectSegment = (segment) => {
    if (selectedSegments.includes(segment)) {
      setSelectedSegments((prev) => prev.filter((s) => s.segmentId !== segment.segmentId));
      return;
    }
    setSelectedSegments((prev) => [...prev, segment]);
  };

  const handleSelectTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags((prev) => prev.filter((t) => t.id !== tag.id));
      return;
    }
    setSelectedTags((prev) => [...prev, tag]);
  };

  const handleApplyFilters = () => {
    setSendStatusFilters(selectedSendStatus);
    setLabelFilters(selectedLabels);
    setSegmentFilters(selectedSegments);
    setTagFilters(selectedTags);
    handleClose();
  };

  return (
    <>
      <Button
        title="Filters"
        LeftIcon={IconFilter}
        onClick={handleClick}
        className={getButtonClassName(open, selectedSendStatus.length > 0)}
        loading={loading}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: 'w-[320px] max-h-[412px] !rounded-lg mt-0.5 !overflow-hidden',
          list: '!p-0',
        }}
      >
        <ul className="max-h-[340px] divide-y divide-gray-50 overflow-y-auto">
          <li className="flex items-center justify-between px-4 py-3">
            <p className="text-base font-semibold text-gray-950">Filters</p>
            <button
              className="text-sm text-gray-600"
              onClick={() => {
                setSelectedSendStatus([]);
                setSelectedLabels([]);
                setSelectedSegments([]);
                setSelectedTags([]);
              }}
            >
              Reset all
            </button>
          </li>

          <MenuItem
            classes={{ root: '!justify-between !py-3 !border-solid' }}
            onClick={() => setSegmentsOpen((prev) => !prev)}
          >
            <p className="text-caption1 font-semibold uppercase text-gray-950">Audience - Segments</p>
            {segmentsOpen ? (
              <IconChevronDown size={16} className="text-gray-600" />
            ) : (
              <IconChevronUp size={16} className="text-gray-600" />
            )}
          </MenuItem>

          <Collapse in={segmentsOpen}>
            <li className="space-y-3 px-4 py-3">
              {[...segments]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((segment) => (
                  <Checkbox
                    key={`checkbox-${segment.segmentId}`}
                    id={segment.segmentId}
                    label={segment.name}
                    value={segment.segmentId}
                    checked={selectedSegments.includes(segment)}
                    onChange={() => handleSelectSegment(segment)}
                    labelClassName="grow"
                  />
                ))}
            </li>
          </Collapse>

          <MenuItem
            classes={{ root: '!justify-between !py-3 !border-solid' }}
            onClick={() => setTagsOpen((prev) => !prev)}
          >
            <p className="text-caption1 font-semibold uppercase text-gray-950">Audience - Tags</p>
            {tagsOpen ? (
              <IconChevronDown size={16} className="text-gray-600" />
            ) : (
              <IconChevronUp size={16} className="text-gray-600" />
            )}
          </MenuItem>

          <Collapse in={tagsOpen}>
            <li className="space-y-3 px-4 py-3">
              {[...tags]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tag) => (
                  <Checkbox
                    key={`checkbox-${tag.id}`}
                    id={tag.id}
                    label={tag.name}
                    value={tag.id}
                    checked={selectedTags.includes(tag)}
                    onChange={() => handleSelectTag(tag)}
                    labelClassName="grow"
                  />
                ))}
            </li>
          </Collapse>

          <MenuItem
            classes={{ root: '!justify-between !py-3 !border-solid' }}
            onClick={() => setStageOpen((prev) => !prev)}
          >
            <p className="text-caption1 font-semibold uppercase text-gray-950">Stage</p>
            {stageOpen ? (
              <IconChevronDown size={16} className="text-gray-600" />
            ) : (
              <IconChevronUp size={16} className="text-gray-600" />
            )}
          </MenuItem>

          <Collapse in={stageOpen}>
            <li className="space-y-3 px-4 py-3">
              {Object.keys(campaignSendStatus).map((sendStatus) => (
                <Checkbox
                  key={`checkbox-${sendStatus}`}
                  id={sendStatus}
                  label={campaignSendStatusLabels[sendStatus]}
                  value={sendStatus}
                  checked={selectedSendStatus.includes(sendStatus)}
                  onChange={() => handleSelectSendStatus(sendStatus)}
                  labelClassName="grow"
                />
              ))}
            </li>
          </Collapse>

          <MenuItem
            classes={{ root: '!justify-between !py-3 !border-solid' }}
            onClick={() => setLabelsOpen((prev) => !prev)}
          >
            <p className="text-caption1 font-semibold uppercase text-gray-950">Labels</p>
            {labelsOpen ? (
              <IconChevronDown size={16} className="text-gray-600" />
            ) : (
              <IconChevronUp size={16} className="text-gray-600" />
            )}
          </MenuItem>

          <Collapse in={labelsOpen}>
            <li className="space-y-3 px-4 py-3">
              {[...labels]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((label) => (
                  <Checkbox
                    key={`checkbox-${label.labelId}`}
                    id={label.labelId}
                    label={label.name}
                    value={label.labelId}
                    checked={selectedLabels.includes(label)}
                    onChange={() => handleSelectLabel(label)}
                    labelClassName="grow"
                  />
                ))}
            </li>
          </Collapse>
        </ul>

        <li className="border-t border-gray-50 p-4">
          <Button title="Apply" color="primary" className="w-full" onClick={handleApplyFilters} />
        </li>
      </Menu>
    </>
  );
};

Filters.propTypes = {
  sendStatusFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSendStatusFilters: PropTypes.func.isRequired,
  labelFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLabelFilters: PropTypes.func.isRequired,
  segmentFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSegmentFilters: PropTypes.func.isRequired,
  tagFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTagFilters: PropTypes.func.isRequired,
};

export default Filters;
